import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import { TwitterTimelineEmbed } from 'react-twitter-embed';
import './Twitter.scss';

function Twitter({ screenName }) {
  return (
    <div className="selfCenter standardWidth">
      <TwitterTimelineEmbed
        noFooter
        noHeader
        noScrollbar
        options={{ height: 345, tweetLimit: 1 }}
        screenName={screenName}
        sourceType="profile"
        noBorders
      />
    </div>
  );
}

export default memo(Twitter);

Twitter.propTypes = checkExtraProps({
  screenName: PropTypes.string,
});
