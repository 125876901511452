import React, { memo, useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { ReactComponent as TwitterLogo } from '../../assets/svgs/Twitter_Logo_Blue.svg';
import Twitter from '../Twitter/Twitter.js';
import Section from '../../components/Section/Section';
import ViewAll from '../../components/ViewAll/ViewAll';

import './BlogsPreview.scss';

import { useSectionTimeSpent } from '../../util';
import TermWrapper from '../TermWrapper/TermWrapper';

function BlogsPreview({ data: { id, name, title } }) {
  const containerRef = useRef(null);

  useSectionTimeSpent(name, containerRef);

  const { allWpBlog } = useStaticQuery(graphql`
    {
      allWpBlog(limit: 3, sort: { order: DESC, fields: dateGmt }) {
        nodes {
          ...BlogPreviewContent
        }
      }
    }
  `);

  return (
    <Section id={id} className="socialnews" ref={containerRef}>
      <div className="socialPostWrapper">
        <div className="BlogList">
          <h2 className="title">{title}</h2>
          <div className="grid-list-container">
            {allWpBlog?.nodes.map(({ date, title, uri, blogTags, blogPostFields }, index) => (
              <div key={index} className="grid-item">
                <div className="BlogGridItem">
                  <div className="mediapostdate">{date}</div>
                  <div className="description">
                    <Link to={uri}>{title}</Link>
                    <TermWrapper terms={blogTags} />
                  </div>
                </div>
              </div>
            ))}
            <div className="grid-item viewallcontainer">
              <div className="BlogGridItem">
                <div className="mediapostdate" />
                <div className="description">
                  <ViewAll link="/blog/" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightSocial" id="twitter">
          <div>
            <div className="twitterhead">
              <TwitterLogo />
              <span className="twitterhandle">
                <a href="https://twitter.com/diemassociation" target="_blank" rel="noopener noreferrer">
                  @DiemAssociation
                </a>
              </span>
            </div>
            <Twitter screenName="diemassociation" />
          </div>
          <div>
            <div className="twitterhead">
              <TwitterLogo />
              <span className="twitterhandle">
                <a href="https://twitter.com/diemdevelopers" target="_blank" rel="noopener noreferrer">
                  @DiemDevelopers
                </a>
              </span>
            </div>
            <Twitter screenName="diemdevelopers" />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default memo(BlogsPreview);

export const fragments = graphql`
  fragment BlogsPreview on WpPage_Flexiblelayout_FlexibleChildren_BlogsPreview {
    id
    jumpToLinkTitle
    name
    title
  }

  fragment BlogsPreview_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_BlogsPreview {
    id
    jumpToLinkTitle
    name
    title
  }
`;
